// src/components/Contact.js
import React from 'react';
import Grid from "@mui/material/Grid2";

const Experience = () => {
  return (
    <div
    id="experience" className="font-container"
      style={{
        display: 'flex',
        justifyContent: 'center', // Horizontally center content
        alignItems: 'center', // Vertically center content
        height: '100vh', // Full viewport height
      }}
    >
      <Grid container spacing={2}  style={{ width: '100%' }}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center content horizontally
              width: '100%',
            }}
          >
            <h2 className="underline-experience">experience</h2>
            <Grid size={8} className="grid-margin">
              <h4>Software Development Intern - FPT Software</h4>
              <p className='small-text'>Jun 2024 - Aug 2024</p>
              <p className='small-text'>Developed an ISO 8583-compliant Android payment app in Kotlin, enabling seamless EMV transactions and integrating with payment systems.</p>
            </Grid>
            <Grid size={8} className="grid-margin">
              <h4>RPA Developer - PAP Community Foundation</h4>
              <p className='small-text'>Mar 2020 - Jun 2020</p>
              <p className='small-text'>Automated yearly registration data processing for child enrollment using Orchestrator to schedule and run Bots weekly.</p>
            </Grid>
            <Grid size={8} className="grid-margin">
              <h4>RPA Developer Intern - National Trades Union Congress</h4>
              <p className='small-text'>Jul 2019 - Dec 2019</p>
              <p className='small-text'>Led the RPA initiative, showcasing its value to stakeholders, managed in-house automation tasks, achieving 40-95% time savings, and provided staff tutorials for RPA project development.</p>
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={12}>
              <a  style={{ fontSize: '1.2rem' }} href="/mirfomar_resume.pdf" download="mirfomar_resume.pdf">download copy</a>
              </Grid>
            </Grid> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Experience;
