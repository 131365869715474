import { Button } from "@mui/material";
import React, { useState }  from "react";

const FabMenu = ({ onClick , activePage}) => {

  // const [activePage, setActivePage] = useState('');

  // const handleClick = (page) => {
  //   setActivePage(page);
  //   onClick(page);
  // };

  const isActive = (page) => activePage === page;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 16,
        right: 16,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Button
        variant="contained"
        onClick={() => onClick("introduction")}
        style={{
          marginBottom: 12, // Space between buttons
          width: "100%", // Make sure all buttons have the same width
          textTransform: "lowercase",
          fontFamily: '"Outfit", sans-serif',
          fontWeight: "400",
          backgroundColor: isActive('introduction') ? "#ff595e" : "transparent", // Fill when active
          borderBottom: "3px solid #ff595e", 
          color: isActive('introduction') ? "white" : "#ff595e",
        }}
      >
        introduction
      </Button>
      <Button
        variant="contained"
        onClick={() => onClick("experience")}
        style={{
          marginBottom: 12, // Space between buttons
          width: "100%", // Make sure all buttons have the same width
          textTransform: "lowercase",
          fontFamily: '"Outfit", sans-serif',
          fontWeight: "400",
          backgroundColor: isActive('experience') ? "#FFCA3A" : "transparent", // Fill when active
          borderBottom: "3px solid #FFCA3A",
          color: isActive('experience') ? "white" : "#FFCA3A",
        }}
      >
        experience
      </Button>
      <Button
        variant="contained"
        onClick={() => onClick("education")}
        style={{
          marginBottom: 12, // Space between buttons
          width: "100%", // Make sure all buttons have the same width
          textTransform: "lowercase",
          fontFamily: '"Outfit", sans-serif',
          fontWeight: "400",
          backgroundColor: isActive('education') ? "#8ac926" : "transparent", // Fill when active
          borderBottom: "3px solid #8ac926",
          color: isActive('education') ? "white" : "#8ac926",
        }}
      >
        education
      </Button>
      <Button
        variant="contained"
        onClick={() => onClick("contact")}
        style={{
          marginBottom: 0, // Space between buttons
          width: "100%", // Make sure all buttons have the same width
          textTransform: "lowercase",
          fontFamily: '"Outfit", sans-serif',
          fontWeight: "400",
          backgroundColor: isActive('contact') ? "#1982C4" : "transparent", // Fill when active
          borderBottom: "3px solid #1982C4",
          color: isActive('contact') ? "white" : "#1982C4",
        }}
      >
        Contact & links
      </Button>
    </div>
  );
};

export default FabMenu;
