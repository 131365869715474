// src/components/Contact.js
import React from 'react';
import Grid from "@mui/material/Grid2";

const Home = () => {
  return (
    <div
    id="introduction" className="font-container"

      style={{
        display: 'flex',
        justifyContent: 'center', // Horizontally center content
        alignItems: 'center', // Vertically center content
        height: '100vh', // Full viewport height
      }}
    >
      <Grid container spacing={2}  style={{ width: '100%' }}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center content horizontally
              width: '100%',
            }}
          >
            <h1>Hello!</h1>
            {/* <Grid container spacing={2}>
              <Grid item xs={12}>
                <p>Learn more about what I do</p>
              </Grid>
            </Grid> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
