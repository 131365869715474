// src/components/Contact.js
import React from "react";
import Grid from "@mui/material/Grid2";

const Education = () => {
  return (
    <div
      id="education"
      className="font-container"
      style={{
        display: "flex",
        justifyContent: "center", // Horizontally center content
        alignItems: "center", // Vertically center content
        height: "100vh", // Full viewport height
      }}
    >
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content horizontally
              width: "100%",
            }}
          >
            <h2 className="underline-education">education</h2>
            <Grid size={12} className="grid-margin">
              <h4 className="centre-text">Singapore Management University</h4>
              <p className="centre-text">Information Systems (BsC)</p>
              <p className="small-text-education">2022-2026</p>
            </Grid>
            <Grid size={12} className="grid-margin">
              <h4 className="centre-text">Temasek Polytechnic</h4>
              <p className="centre-text">Information Technology (Dip)</p>
              <p className="small-text-education">2016-2020</p>
            </Grid>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <a
                  style={{ fontSize: "1.2rem" }}
                  href="/mirfomar_resume.pdf"
                  download="mirfomar_resume.pdf"
                >
                  download copy
                </a>
              </Grid> */}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Education;
