// src/components/Contact.js
import React from "react";
import Grid from "@mui/material/Grid2";

// this page would probably just link my github and my linkedin

const Contact = () => {
  return (
    <div
      id="contact"
      className="font-container"
      style={{
        display: "flex",
        justifyContent: "center", // Horizontally center content
        alignItems: "center", // Vertically center content
        height: "100vh", // Full viewport height
      }}
    >
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content horizontally
              width: "100%",
            }}
          >
            <h2 className="underline-contact">Contact</h2>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p>
                  <a href="https://www.linkedin.com/in/mirfomar/" target="_blank">linkedIn</a>
                  <br />  
                  <a href="https://github.com/economicdonut" target="_blank">github</a>
                </p>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
